.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F1B66E;
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(5px + 2vmin);
  color: black;
  border-bottom: 1px solid #E4E4E4
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.divTitle {
  padding: 15px 10px 5px 15px;
}

.divLogout {
  padding: 5px 10px 5px 5px;
}

.copy-container {
  background-color: red;
}
.tags {
  border-radius: 25px;
  background-color: #F8DFBA;
  color: #9E9E9F;
  padding: 5px;
  text-decoration: none;
  margin: 2px
 }
.highlight {
 background-color: #C9D3D7;
 padding: 2px;
}
.display-linebreak {
  white-space: pre-line;
  text-align: left;
}
body {
  background-color: #Ffffff;
  background-image: url("./assets/Pattern.png");
  background-repeat: repeat;
}

footer{
  background-color: #BDBDBD;
  position:absolute;
  left:0;
  bottom:0;
  right:0;
}

select {
  /* Reset Select */
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0;
  color: rgba(0, 0, 0, 0.54);
  background-color: #fff;
  background-image: none;
  cursor: pointer;
  font-family: "Josefin Sans",Open Sans;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
  margin-bottom:-15px;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 25ch;
  height: 3em;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
}
/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #fff;
  transition: .25s all ease;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}
/* Transition */
.select:hover::after {
  color: #F1B66E;
}

